import * as noUiSlider from 'nouislider';

export class Range {
  constructor() {
    document.querySelectorAll('[data-range]').forEach(($range: HTMLElement) => {
      const min = Number($range.dataset.rangeMin);
      const max = Number($range.dataset.rangeMax);
      const unit = $range.dataset.range;

      noUiSlider
        .create($range, {
          start: [min, max],
          range: { min, max },
          tooltips: true,
          format: {
            to: v => `${Math.floor(v)} ${unit}`,
            from: v => v,
          },
        })
        .on('update', (v: String[]) => {
          const min = Number(v[0].replace(` ${unit}`, ''));
          const max = Number(v[1].replace(` ${unit}`, ''));
          this.update(min, max);
        });
    });
  }
  public update(min, max) {
    if (document.querySelector('[data-range-size]')) {
      document.querySelectorAll('[data-range-size]').forEach(($item: HTMLElement) => {
        const size = Number($item.dataset.rangeSize);
        $item.classList[size >= min && size <= max ? 'remove' : 'add']('is-hidden');
      });
    }
  }
}
