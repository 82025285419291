import cssVarsPonyfill from 'css-vars-ponyfill';

import betterScroll from 'better-scroll';
import * as Turbolinks from 'turbolinks';

import { Expand } from './utilities/expand';
import { Former } from './utilities/former';
import { Fit } from './utilities/fit';
import { Modal } from './utilities/modal';

import { Categories } from './components/categories';
import { Equalizer } from './components/equalizer';
import { Jury } from './components/jury';
import { News } from './components/news';
import { Range } from './components/range';
import { Results } from './components/results';

let isRevealing = false;
const revealQueue = [];
let $page = document.querySelector('[data-page]');

const setReveal = () => {
  if (!isRevealing && revealQueue[0]) {
    const $el = revealQueue[0];
    const t = String(parseFloat(getComputedStyle($el)['transitionDuration']));
    // const enterTime = t.indexOf('ms') > -1 ? parseFloat(t) : parseFloat(t) * 1000 || 2000;
    const enterTime = t.indexOf('ms') > -1 ? parseFloat(t) : parseFloat(t) * 1000 || 4000;
    const enterDelay = $el.dataset.appear ? Number($el.dataset.appear) : 200;

    isRevealing = true;
    $el.classList.add('will-enter');

    setTimeout(() => {
      $el.classList.add('is-entering');

      setTimeout(() => {
        revealQueue.shift();
        isRevealing = false;
        setReveal();
      }, enterDelay);

      setTimeout(() => {
        $el.classList.remove('is-entering');
        $el.classList.add('has-entered');
        setTimeout(() => {
          $el.classList.remove('will-enter');
        }, 50);
      }, enterTime);
    }, 50);
  }
};

export function setReveals() {
  document.querySelectorAll('[data-appear]:not(.has-entered)').forEach(($el: HTMLElement, i) => {
    const box = $el.getBoundingClientRect();
    const anchor = box.top + window.scrollY;
    if (!$el.matches('.is-ready') && !$el.matches('.is-clone')) {
      $el.classList.add('is-ready');
      if (window.innerHeight + window.scrollY >= anchor) {
        revealQueue.push($el);
        setReveal();
      } else {
        document.addEventListener(
          'scroll',
          function scrollHandler() {
            if (window.innerHeight + window.scrollY >= anchor) {
              revealQueue.push($el);
              setReveal();
              document.removeEventListener('scroll', scrollHandler, true);
            }
          },
          true,
        );
      }
    }
  });
}

const onEnter = () => {
  $page = document.querySelector('[data-page]');
  $page.classList.add('is-entering');
  setTimeout(() => {
    $page.classList.remove('is-entering');
    $page.classList.add('has-entered');
    setReveals();
  }, 800);
};

export function setEqualize() {
  if (document.querySelector('[data-equalize]')) {
    const types = [];
    document.querySelectorAll('[data-equalize]').forEach(($el: any) => {
      const type = $el.dataset['equalize'];
      if (types.indexOf(type) < 0) {
        types.push(type);
        new Equalizer(document.querySelectorAll(`[data-equalize="${type}"]`));
      }
    });
  }
}

export const init = () => {
  cssVarsPonyfill();

  setEqualize();

  if (document.querySelector('[data-expand]')) {
    document.querySelectorAll('[data-expand]').forEach($el => new Expand($el));
  }

  document.querySelectorAll('form').forEach(($form: HTMLFormElement) => {
    if (!$form.matches('.custom-submit')) new Former($form);
  });

  if (document.querySelector('[data-scroller]')) {
    document.querySelectorAll('[data-scroller]').forEach(($el: HTMLElement) => {
      if ($el.childElementCount > 7) {
        const scroller = new betterScroll($el.parentElement, {
          mouseWheel: true,
          scrollbar: {
            interactive: true,
            // fade: true,
          },
          // disableMouse: true,
        });
        $el.parentElement.classList.add('has-scrollbar');
        $el.parentElement.addEventListener('mouseenter', (e: any) => {
          setTimeout(() => {
            if (scroller) scroller.refresh(), 361;
          });
        });
      }
      $el.parentElement.classList.add('is-ready');
    });
  }

  if (document.querySelector('[data-fit]')) {
    document.querySelectorAll('[data-fit]').forEach(($fit: HTMLElement) => {
      $fit.querySelectorAll('[data-fit-item]').forEach($el => new Fit($el as HTMLElement, $fit));
    });
  }

  if (document.querySelector('[data-modal]')) {
    document.querySelectorAll('[data-modal]').forEach(($modal: HTMLElement) => new Modal($modal));
  }

  document.querySelectorAll('[data-filter-trigger]').forEach(($link: any) => {
    $link.classList[$link.href === location.href ? 'add' : 'remove']('is-active');
  });

  if (document.querySelector('[data-jury-slider]')) new Jury();

  if (document.querySelector('[data-results-slider]')) new Results();

  if (document.querySelector('[data-range]')) new Range();

  if (document.querySelector('[data-more-trigger]')) new News();

  if (document.querySelector('[data-categories]')) new Categories();

  if (document.querySelector('[data-select-link]')) {
    document.querySelectorAll('[data-select-link]').forEach(($select: any) => {
      $select.addEventListener('change', e => Turbolinks.visit(e.target.value));
    });
  }

  if (!document.querySelector('[data-hero]')) {
    (document.querySelector('[data-visual-overlay]') as HTMLElement).style.opacity = '1';
  } else {
    (document.querySelector('[data-visual-overlay]') as HTMLElement).style.opacity = '';
  }

  setTimeout(onEnter, 300);
};
