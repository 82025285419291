// import { setReveals, setEqualize } from '../init';

export class Categories {
  $root: HTMLElement = document.querySelector('[data-categories]');
  $filter: HTMLElement = document.querySelector('[data-categories-filter]');
  $filterLabel: HTMLElement = document.querySelector('[data-categories-filter-label]');
  $filterTriggers: NodeListOf<HTMLElement> = document.querySelectorAll(
    '[data-categories-filter-trigger]',
  );
  $select: HTMLSelectElement = document.querySelector('[data-categories-select]');
  $options: NodeListOf<HTMLOptionElement> = document.querySelectorAll('[data-categories-option]');
  $items: NodeListOf<HTMLElement> = document.querySelectorAll('[data-categories-item]');
  activeCategorySlug: string | null = null;
  activeCategoryLabel: string | null = null;
  $activeCategoryItem: HTMLElement = document.querySelector('[data-categories-item].is-active');

  constructor() {
    this.$filterTriggers.forEach($trigger =>
      $trigger.addEventListener('click', () => {
        this.activeCategorySlug = $trigger.dataset.categoriesFilterTrigger;
        this.activeCategoryLabel = $trigger.innerText;
        this.setCategory(this.activeCategorySlug);
      }),
    );
    this.$select.addEventListener('change', () => {
      this.activeCategorySlug = this.$select.value;
      this.activeCategoryLabel = this.$select.options[this.$select.selectedIndex].text;
      console.log(this.activeCategoryLabel);
      this.setCategory(this.activeCategorySlug);
    });
  }

  setCategory(category) {
    // MOUSELEAVE!!!
    this.activeCategorySlug = category;
    this.$items.forEach($item => $item.classList.remove('is-active'));
    this.$filterTriggers.forEach($trigger => $trigger.classList.remove('is-active'));
    document.querySelector('[data-categories-item="' + category + '"]').classList.add('is-active');
    document
      .querySelector(`[data-categories-filter-trigger="${category}"]`)
      .classList.add('is-active');
    this.$select.value = category;
    this.$filter.classList.add('is-collapsed');
    this.$filterLabel.innerText = this.activeCategoryLabel;
    setTimeout(() => this.$filter.classList.remove('is-collapsed'), 600);
  }
}
