import debounce from 'debounce';

export class Equalizer {
  $items: NodeListOf<HTMLElement>;
  max: number = 0;

  constructor($items) {
    this.$items = $items;
    this.set();
    window.onresize = debounce(() => this.set(), 200);
  }

  public set() {
    this.max = 0;
    new Promise((resolve: any) => {
      this.$items.forEach(($el: HTMLElement) => {
        $el.style.minHeight = '';
        this.max = $el.clientHeight > this.max ? $el.clientHeight : this.max;
      });
      resolve(this.max);
    }).then((max: number) => {
      this.$items.forEach(($item: HTMLElement) => {
        if (window.innerWidth > 767) $item.style.minHeight = `${max}px`;
      });
    });
  }
}
