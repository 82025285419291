import '../scss/index.scss';

import * as Turbolinks from 'turbolinks';
import animatedScrollTo from 'animated-scroll-to';

import { Cookiebar } from './utilities/cookiebar';
import { Device } from './helpers/device';

// import { init, setReveals, setEqualize } from './init';
import { init } from './init';

(() => {
  if (typeof (NodeList as any).prototype.forEach !== 'function') {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      (Element.prototype as any)['msMatchesSelector'] || Element.prototype.webkitMatchesSelector;
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
      let el = this;
      do {
        if (el.matches(s)) return el;
        el = (el.parentElement as HTMLElement) || (el.parentNode as HTMLElement);
      } while (el !== null && el.nodeType === 1);
      return null;
    };
  }
  return false;
})();

window.addEventListener('load', () => {
  let fromTop = 0;
  let visualSet = false;
  let canVisit = false;
  let videoPlaying = false;
  let videoSuspended = false;
  const $visualOverlay: HTMLElement = document.querySelector('[data-visual-overlay]');
  const $visualVideo: HTMLVideoElement = document.querySelector('[data-visual-video]');
  const $header: HTMLElement = document.querySelector('[data-header]');
  const $navItems = document.querySelectorAll('[data-nav-item]');
  const delay = 500;

  const $cloneAppend = document.querySelector('[data-clone-append]');
  const $cloneCard = document.querySelector('[data-clone-card]');

  let targetNewsPage = 2;

  document.body.classList.add('is-loading');

  $visualVideo.addEventListener('play', () => {
    $visualVideo.classList.add('is-playing');
  });

  // $visualVideo.addEventListener('suspend', () => {
  //   if (!videoPlaying) $visualVideo.classList.add('is-suspended');
  // });

  setTimeout(() => {
    document.body.classList.remove('is-loading');
    document.body.classList.add('has-loaded');
  }, 2000);

  new Cookiebar();

  new Device();

  init();

  Turbolinks.start();

  document.addEventListener('turbolinks:load', () => {
    init();
    fromTop = 0;
    targetNewsPage = 2;
    canVisit = false;
    document.body.classList.remove('preload');
  });

  document.addEventListener('turbolinks:before-visit', (e: any) => {
    // const hash = e.data.url.replace(location.origin + location.pathname, '').replace('#', '');
    const hash: string = e.data.url.substring(e.data.url.indexOf('#') + 1);
    const navOpen: boolean = document.body.matches('.nav-open');
    document.body.classList.remove('nav-open');
    $navItems.forEach($item => $item.classList.remove('is-open'));
    if (!canVisit) e.preventDefault();
    if (
      e.data.url.match(/#[a-z]+/) &&
      e.data.url.indexOf(location.pathname) > 0 &&
      location.pathname !== '/'
    ) {
      location.hash = hash;
      const $item = document.querySelector(`[data-expand-item="${hash}"]`);
      if ($item) {
        if (!$item.matches('.is-open')) {
          ($item.querySelector('[data-expand-trigger]') as HTMLElement).click();
        }
        if (navOpen) {
          animatedScrollTo($item as HTMLElement, { speed: 800, offset: -$header.clientHeight });
        }
      }
    } else if (e.data.url !== location.href && !canVisit) {
      document.body.classList.remove('has-entered', 'is-entering');
      document.body.classList.add('is-leaving');
      document.querySelector('[data-page]').classList.add('is-leaving');
      setTimeout(() => {
        canVisit = true;
        Turbolinks.visit(e.data.url);
        document.body.classList.remove('has-entered', 'is-leaving');
        document.body.classList.add('is-entering');
      }, delay);
    }
  });

  document.addEventListener('click', (e: Event) => {
    const $target = e.target as HTMLElement;
    const target = selector => $target.matches(selector) || $target.closest(selector);

    if (target('[data-navicon]')) {
      document.body.classList[document.body.matches('.nav-open') ? 'remove' : 'add']('nav-open');
      if (document.querySelector('[data-nav-item].is-open')) {
        document.querySelector('[data-nav-item].is-open').classList.remove('is-open');
      }
    }

    if (target('[data-scrollto]')) {
      if ($target.dataset && $target.dataset.scrollto) {
        const $scrollTarget: HTMLElement = document.querySelector($target.dataset.scrollto);
        if ($scrollTarget) {
          animatedScrollTo($scrollTarget, { speed: 800, offset: -$header.clientHeight });
        }
      }
    }

    if (target('[data-nav-trigger]')) {
      const $targetItem = $target.closest('[data-nav-item]');
      const targetOpen = $targetItem.classList.contains('is-open');
      $navItems.forEach($item => $item.classList.remove('is-open'));
      $targetItem.classList[targetOpen ? 'remove' : 'add']('is-open');
    }
  });

  const headerThreshold = 1;
  let lastPos = null;
  let speed = 0;
  let pos = window.scrollY;
  let scrollTimer = null;
  let headerUp = pos > headerThreshold;
  let headerFixed = pos > headerThreshold;

  document.addEventListener('scroll', () => {
    if (document.querySelector('[data-hero]')) {
      if (window.scrollY < 200) {
        fromTop = window.scrollY;
        $visualOverlay.style.opacity = `${fromTop / 200}`;
        visualSet = false;
      } else if (!visualSet) {
        visualSet = true;
        $visualOverlay.style.opacity = '1';
      }
    }

    if (lastPos !== null) speed = pos - lastPos;
    lastPos = pos;
    clearTimeout(scrollTimer);
    scrollTimer = setTimeout(() => {
      lastPos = null;
      speed = 0;
    }, 50);
    pos = window.scrollY;
    if (pos > headerThreshold) {
      if (headerUp && speed < 0) {
        headerUp = false;
        window.requestAnimationFrame(() => document.body.classList.remove('header-up'));
      } else if (!headerUp && speed > 0) {
        headerUp = true;
        window.requestAnimationFrame(() => document.body.classList.add('header-up'));
      }
      if (!headerFixed) {
        headerFixed = true;
        document.body.classList.add('header-fixed');
      }
    } else if (headerFixed) {
      headerFixed = false;
      document.body.classList.remove('header-fixed');
    }
  });

  window.addEventListener('popstate', e => Turbolinks.visit(location.href));

  setTimeout(() => document.documentElement.classList.remove('preload'), 300);
});
