export function fixBody(state: Boolean) {
  document.body.style.paddingRight = state
    ? `${window.innerWidth - document.body.clientWidth}px`
    : '';
  document.body.style.touchAction = state ? 'none' : '';
  document.body.style.overflow = state ? 'hidden' : '';
  document.body.style.pointerEvents = state ? 'none' : '';
  document.documentElement.style.paddingRight = state
    ? `${window.innerWidth - document.body.clientWidth}px`
    : '';
  document.documentElement.style.touchAction = state ? 'none' : '';
  document.documentElement.style.overflow = state ? 'hidden' : '';
  document.documentElement.style.pointerEvents = state ? 'none' : '';
}
