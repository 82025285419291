import choicesJs from 'choices.js';

interface IObject {
  [key: string]: any;
}

interface IField {
  name: string;
  type: string;
  value: string;
  $input: HTMLInputElement;
  $parent: HTMLElement;
  hasError: boolean;
  hasValue: boolean;
  isDirty: boolean;
  isFocused: boolean;
  isValid: boolean;
}

export class Former {
  public $form: HTMLFormElement;
  public $inputs: NodeListOf<HTMLInputElement>;
  public $firstFocus: HTMLInputElement = null;
  public fields: {
    [key: string]: IField;
  };
  public isDirty: boolean = false;
  public isValid: boolean = false;
  public url: string;

  constructor($form: HTMLFormElement, customSubmission?: Function) {
    this.$form = $form;
    this.$form.isDirty = false;
    this.$form.isValid = true;
    this.$form.url = $form.getAttribute('action');
    this.$form.fields = {};
    this.$form.$inputs = $form.querySelectorAll('input, select, textarea');
    this.$form.formSubmission = this.formSubmission;
    this.$form.customSubmission = customSubmission;
    this.init();
  }

  public init() {
    const $formDones: NodeListOf<HTMLInputElement> = this.$form.querySelectorAll(
      '[data-form-done]',
    );
    this.$form.setAttribute('novalidate', 'true');
    this.$form.$inputs.forEach(($input: HTMLInputElement) => {
      const $parent: HTMLElement = $input.parentElement;
      const type =
        $input.tagName.toLowerCase() === 'input'
          ? $input.getAttribute('type')
          : $input.tagName.toLowerCase();
      let value: string = $input.value;

      if (type === 'checkbox') value = $input.checked ? 'checked' : '';

      if (type === 'radio') value = $input.checked ? $input.value : '';

      let selectChoices: any;

      if (type === 'select') {
        selectChoices = new choicesJs($input, {
          searchEnabled: false,
          removeItemButton: false,
          shouldSort: false,
          itemSelectText: '',
        });
        selectChoices.containerOuter.element.addEventListener('keydown', (event: any) => {
          if (event.which === 32) {
            // event.preventDefault();
          }
        });
      }
      const name: string = $input.getAttribute('name');
      const isValid: boolean = true;
      const hasError: boolean = false;
      const isDirty: boolean = false;
      const isFocused: boolean = false;
      const hasValue: boolean = value.length > 0;

      $parent.classList[hasValue ? 'add' : 'remove']('has-value');
      $input.classList[hasValue ? 'add' : 'remove']('has-value');

      $input.addEventListener('focus', this.focusHandler(name));
      $input.addEventListener('blur', this.blurHandler(name));
      $input.addEventListener('change', this.changeHandler(name));
      $input.addEventListener('keyup', this.changeHandler(name));

      const addToFields = !$input.checked && type === 'radio' ? false : true;

      if (addToFields) {
        this.$form.fields[name] = {
          type,
          value,
          name,
          isValid,
          isDirty,
          isFocused,
          hasValue,
          hasError,
          $input,
          $parent,
        };
      }
    });

    this.$form.addEventListener('submit', (e: Event) => {
      e.preventDefault();
      this.$form.isValid = true;
      this.$form.isDirty = true;
      this.$form.$firstFocus = null;
      Object.keys(this.$form.fields).forEach((name: string) => {
        this.validateField(name);
      });
      if (!this.$form.isValid) {
        this.$form.$firstFocus.focus();
      } else {
        if (this.$form.customSubmission) {
          this.$form.customSubmission(e);
        } else {
          this.formSubmission(this.$form);
        }
      }
    });
    $formDones.forEach(($formDone: HTMLElement) => {
      $formDone.addEventListener('click', () => {
        this.$form.classList.remove('has-success', 'has-error');
        setTimeout(() => {
          this.$form.classList.remove('in-perspective');
        }, 1000);
      });
    });
  }

  public focusHandler(name: string): EventListener {
    return (f) => {
      const field = this.$form.fields[name];
      field.isFocused = true;
      field.$input = f.target;
      field.$parent = field.$input.parentElement;
      field.$input.classList.add('is-focused');
      field.$parent.classList.add('is-focused');
    };
  }

  public blurHandler(name: string): EventListener {
    return (f) => {
      const field = this.$form.fields[name];
      field.isFocused = false;
      field.isDirty = true;
      field.$input = f.target;
      field.$parent = field.$input.parentElement;
      field.$input.classList.remove('is-focused');
      field.$parent.classList.remove('is-focused');
      this.validateField(name);
    };
  }

  public changeHandler(name: string): EventListener {
    return (f) => {
      const field = this.$form.fields[name];
      field.$input = f.target;
      field.$parent = field.$input.parentElement;
      if (field.type === 'radio') {
        this.$form.querySelectorAll(`input[name="${name}"]`).forEach(($i: any) => {
          $i.classList.remove('has-value');
          $i.parentElement.classList.remove('has-value');
        });
        field.value = field.$input.checked ? field.$input.value : '';
      } else {
        field.value = field.$input.value;
      }
      field.hasValue = field.value.length > 0;
      if (field.isDirty) {
        this.validateField(name);
      }
      field.$input.classList[field.hasValue ? 'add' : 'remove']('has-value');
      field.$parent.classList[field.hasValue ? 'add' : 'remove']('has-value');
      if (
        field.type === 'file' &&
        field.$parent.querySelector('[data-file-name]') &&
        typeof field.$input.files[0] !== 'undefined'
      ) {
        field.$parent.querySelector('[data-file-name]').innerText = field.$input.files[0].name;
      }
    };
  }

  public formSubmission($form: HTMLFormElement) {
    const formData: FormData = new FormData();
    Object.keys($form.fields).forEach((field) => {
      formData.append(field, $form.fields[field]['$input'].value);
    });
    $form.classList.add('in-perspective');
    fetch($form.url, {
      body: formData,
      method: 'post',
    })
      .then((r) => {
        return r.text();
      })
      .then((response) => {
        $form.classList.add(`has-${response}`);
      })
      .catch((error) => {
        console.log(error);
        $form.classList.add('has-error');
      });
  }

  public validateField(name: string) {
    this.$form.fields[name].isDirty = true;

    const field: IField = this.$form.fields[name];
    const type: string = field['type'];
    const $parent: HTMLElement = field['$parent'];
    const $input: HTMLInputElement = field['$input'];

    let value: string = $input.value;

    if (type === 'checkbox') {
      value = $input.checked ? 'checked' : '';
    }

    if (type === 'radio') {
      value = $input.checked ? $input.value : '';
    }

    let isValid: boolean = true;
    let hasError: boolean = false;
    const hasValue: boolean = value.length > 0;

    //// check for required
    if ($input.hasAttribute('required')) {
      isValid = hasValue ? isValid : false;
    }

    //// check for email
    if (type === 'email') {
      const emailRegex: RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      isValid = emailRegex.test($input.value) ? isValid : false;
    }

    //// check for tel
    if (type === 'tel') {
      const telRegex: RegExp = /[()+0-9\s]+$/;
      isValid = telRegex.test($input.value) && value.length > 7 ? isValid : false;
    }

    //// check for minlength
    if ($input.hasAttribute('minlength')) {
      const minlength: number = parseInt($input.getAttribute('minlength'), 10);
      isValid = $input.value.length >= minlength ? isValid : false;
    }

    //// check for pattern
    if ($input.hasAttribute('pattern')) {
      const regex: RegExp = new RegExp($input.getAttribute('pattern'));
      isValid = regex.test($input.value) ? isValid : false;
    }

    //// check for file type
    if ($input.hasAttribute('accept')) {
      let match = false;
      if (typeof $input.files[0] !== 'undefined') {
        const acceptedTypes: string[] = $input
          .getAttribute('accept')
          .trim()
          .split(',');
        acceptedTypes.forEach((acceptedType) => {
          match = $input.files[0].type.includes(acceptedType.replace('.', '')) ? true : match;
        });
        console.log($input.files[0]);
        isValid = match;
      }
    }

    //// check for match
    if ($input.hasAttribute('data-match')) {
      const match: string = $input.getAttribute('data-match');
      isValid =
        $input.value === this.$form.fields[match].value &&
        this.$form.fields[match].isDirty &&
        !this.$form.fields[match].hasError
          ? isValid
          : false;
    }

    hasError = !isValid;
    this.$form.isValid = hasError ? false : this.$form.isValid;
    this.$form.$firstFocus = hasError && !this.$form.$firstFocus ? $input : this.$form.$firstFocus;
    $parent.classList[hasError ? 'add' : 'remove']('has-error');
    $input.classList[hasError ? 'add' : 'remove']('has-error');
  }
}
