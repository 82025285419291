import { setReveals, setEqualize } from '../init';

export class News {
  $moreTrigger: HTMLElement = document.querySelector('[data-more-trigger]');
  $cloneAppend: HTMLElement = document.querySelector('[data-clone-append]');
  $cloneCard: HTMLElement = document.querySelector('[data-clone-card]');
  endpoint: string;
  targetNewsPage: number = 2;

  constructor() {
    this.endpoint = this.$moreTrigger.dataset['moreTrigger'] + '=' + this.targetNewsPage;
    this.$moreTrigger.addEventListener('click', () => this.fetchNews());
  }

  fetchNews() {
    fetch(this.endpoint)
      .then((r: Response) => r.text())
      .then((response: any) => {
        const parsed = JSON.parse(response);
        if (this.$cloneCard && parsed.articles.length) {
          this.targetNewsPage += 1;
          this.endpoint = this.$moreTrigger.dataset['moreTrigger'] + '=' + this.targetNewsPage;
          parsed.articles.forEach((article: any) => {
            const $newCard: any = this.$cloneCard.cloneNode(true);
            $newCard.querySelector('[data-clone-background]').style.backgroundImage = `url(${
              article['article_thumb']
            }`;
            $newCard.querySelector('[data-clone-title]').innerText = article['article_title'];
            $newCard.querySelector('[data-clone-date]').innerText = article['article_date'];
            $newCard.querySelector('[data-clone-tag]').innerText = article['category_name'];
            $newCard
              .querySelector('[data-clone-link]')
              .setAttribute('href', article['article_link']);
            $newCard
              .querySelector('[data-clone-tag]')
              .setAttribute('href', article['category_link']);
            $newCard.classList.remove('is-clone');
            this.$cloneAppend.append($newCard);
          });
          setEqualize();
          setReveals();
        }
        if (!Boolean(parsed['is_more'])) this.$moreTrigger.classList.add('is-hidden');
      })
      .catch((error: any) => {
        console.log('error:');
        console.log(error);
      });
  }
}
