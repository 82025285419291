import swiper from 'swiper';

export class Results {
  constructor() {
    const slider = new swiper('[data-results-slider]', {
      speed: 800,
      simulateTouch: false,
      autoplay: {
        delay: 4000,
      },
      loop: true,
      pagination: {
        el: '.results__state',
        type: 'fraction',
      },
      navigation: {
        prevEl: '.results__nav--prev',
        nextEl: '.results__nav--next',
      },
    });
  }
}
