import * as jsCookie from 'js-cookie';
import { slideDown, slideUp, slideStop } from 'slide-anim';

export class Cookiebar {
  hasCookie: boolean = Boolean(jsCookie.get('cookiebar')) || false;
  $bar: HTMLElement = document.querySelector('[data-cookiebar]');
  appPadding: number =
    Number(window.getComputedStyle(document.body).paddingTop.replace('px', '')) || 0;
  barHeight: number;
  $accept: HTMLElement = document.querySelector('[data-cookiebar-accept]');
  endpoint: string;

  constructor() {
    if (this.$bar) this.init();
  }

  private init(): void {
    const time = 800;
    this.barHeight = this.$bar.clientHeight;
    this.endpoint = this.$bar.dataset.cookiebar;
    this.$bar.classList[this.hasCookie ? 'remove' : 'add']('is-active');
    document.body.classList[this.hasCookie ? 'remove' : 'add']('has-cookiebar');
    if (this.hasCookie) {
      this.$bar.parentNode.removeChild(this.$bar);
    } else {
      slideDown(this.$bar, { duration: time });
      document.body.classList.add('header-fixed');
    }
    if (this.$accept) {
      this.$accept.onclick = () => {
        fetch(this.endpoint)
          .then((r: Response) => {
            return r.text();
          })
          .then((response: String) => {
            if (response === 'success') {
              this.hasCookie = true;
              this.$bar.classList.remove('is-active');
              document.body.classList.remove('has-cookiebar');
              slideUp(this.$bar, { duration: time }).then(() => {
                this.$bar.parentNode.removeChild(this.$bar);
              });
            } else {
              console.log('error');
            }
          })
          .catch((error: any) => {
            console.log('error');
          });
      };
    }
  }
}
