import { slideDown, slideUp, slideStop } from 'slide-anim';
import { setReveals } from '../init';

interface IExpandItem {
  $parent: HTMLElement;
  $item: HTMLElement;
  isOpen: boolean;
  $trigger?: HTMLElement;
  $content?: HTMLElement;
  id?: string;
  $siblings?: NodeListOf<HTMLElement>;
}

export class Expand {
  constructor($el?) {
    if ($el) this.init($el);
  }

  public init($el): void {
    const $items: NodeListOf<HTMLElement> = $el.querySelectorAll('[data-expand-item]');

    $items.forEach(($item: HTMLElement) => {
      const item: IExpandItem = {
        $item,
        $parent: $el,
        $trigger: $item.querySelector('[data-expand-trigger]'),
        $content: $item.querySelector('[data-expand-content]'),
        id: $item.dataset.expandItem,
        isOpen: $item.matches('.is-open'),
      };

      item.$trigger.addEventListener('click', (e: Event) => {
        if (!$item.matches('.is-open')) {
          slideStop(item.$content);
          item.$item.classList.add('slidedown-start');
          slideDown(item.$content, { duration: 800 }).then(() => {
            item.$item.classList.add('is-open');
            item.$item.classList.remove('slidedown-start');
            window.location.hash = item.id;
          });
        } else {
          $items.forEach(($i: HTMLElement) => {
            if (!$i.matches('.has-entered')) $i.classList.remove('is-ready');
          });
          slideStop(item.$content);
          $item.classList.add('slideup-start');
          slideUp(item.$content, { duration: 800 }).then(() => {
            $item.classList.remove('slideup-start', 'is-open');
            setReveals();
          });
        }
      });
    });

    this.openHash();
  }

  public openHash(): void {
    const $items: NodeListOf<HTMLElement> = document.querySelectorAll('[data-expand-item]');
    const $header: HTMLElement = document.querySelector('[data-header]');
    if ($items.length > 0 && window.location.hash) {
      const targetId = window.location.hash.replace('#', '');
      $items.forEach(($item: HTMLElement) => {
        if ($item.dataset.expandItem === targetId) {
          $item.classList.add('will-enter');
          setTimeout(() => {
            $item.classList.add('is-entering');
            setTimeout(() => {
              $item.classList.remove('is-entering');
              $item.classList.add('has-entered');
              setTimeout(() => {
                $item.classList.remove('will-enter');
              }, 50);
            }, 1000);
          }, 50);
          if (!$item.matches('.is-open')) {
            ($item.querySelector('[data-expand-trigger]') as HTMLElement).click();
          }
          document.body.classList.add('header-fixed');
        }
      });
    }
  }
}
