import { onThrottledResize } from './onThrottledResize';

export class Device {
  public isSet: boolean = false;
  public isPortrait: boolean = screen.width < screen.height;
  public ratio: number = screen.width / screen.height;
  public isTouch: boolean = typeof window.ontouchstart !== 'undefined';
  public isMob: boolean = screen.width < 768;
  public isTablet: boolean = screen.width < 1025 && screen.width > 767;
  public isLaptop: boolean = screen.width > 1024;
  public winW: number = window.innerWidth;
  public winH: number = window.innerHeight;
  public bodyW: number = document.body.clientWidth;
  public bodyH: number = document.body.clientHeight;
  public scrollbarW: number = window.innerWidth - document.body.clientWidth;
  public hasScrollbar: boolean = document.body.clientWidth !== window.innerWidth;
  public isIOS: boolean = ['iPad', 'iPhone', 'iPod'].indexOf(navigator.platform) >= 0;
  public OS: string;
  public ua: string = window.navigator.userAgent;

  constructor() {
    if (this.ua.indexOf('Windows NT 10.0') !== -1) this.OS = 'win-10';
    if (this.ua.indexOf('Windows NT 6.2') !== -1) this.OS = 'win-8';
    if (this.ua.indexOf('Windows NT 6.1') !== -1) this.OS = 'win-7';
    if (this.ua.indexOf('Windows NT 5.1') !== -1) this.OS = 'win-xp';
    if (this.ua.indexOf('Linux') !== -1) this.OS = 'linux';
    if (this.ua.indexOf('Mac') !== -1) this.OS = 'mac-os';
    this.init();
  }

  public init() {
    document.body.classList.add(this.isIOS ? 'is-ios' : 'no-ios');
    document.body.classList.add(this.isTouch ? 'is-touch' : 'no-touch');
    document.body.classList.add(this.isMob ? 'is-mob' : 'no-mob');
    document.body.classList.add(this.isTablet && this.isTouch ? 'is-tablet' : 'no-tablet');
    document.body.classList.add(this.isLaptop ? 'h' : 'no-laptop');
    document.body.classList.add(this.OS);
    document.body.classList.add(
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'is-safari' : 'no-safari',
    );

    // var isSafari = /constructor/i.test(window.HTMLElement);

    // export const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (this.ua.indexOf('Windows') !== -1) document.body.classList.add('has-scrollbar');
    this.set();
    window.onresize = this.set;
  }
  public check() {
    this.isSet = false;
    this.winW = window.innerWidth;
    this.winH = window.innerHeight;
    this.bodyW = document.body.clientWidth;
    this.bodyH = document.body.clientHeight;
    this.ratio = window.innerWidth / window.innerHeight;
    this.scrollbarW = window.innerWidth - document.body.clientWidth;
    this.hasScrollbar = document.body.clientWidth !== window.innerWidth;
  }

  public set() {
    if (!this.isSet) {
      this.isSet = true;
      setTimeout(this.check, 100);
    }
  }
}
