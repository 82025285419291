import { fixBody } from '../helpers/fixBody';

export class Modal {
  isOpen: boolean;
  $modal: HTMLElement;
  id: string;

  constructor($el) {
    this.$modal = $el;
    this.id = this.$modal.dataset['modal'];
    this.init();
  }
  public open() {
    location.href = `#${this.id}`;
    this.isOpen = true;
    this.$modal.classList.add('is-entering');
    setTimeout(() => {
      this.$modal.classList.add('is-active');
      this.$modal.classList.remove('is-entering');
      fixBody(true);
    }, 1);
  }
  public close() {
    // location.hash = '';
    this.isOpen = false;
    this.$modal.classList.add('is-leaving');
    setTimeout(() => {
      this.$modal.classList.remove('is-active');
      this.$modal.classList.remove('is-leaving');
      fixBody(false);
    }, 1000);
  }
  public init() {
    if (location.hash.replace('#', '') === this.id) this.open();
    if (document.querySelector(`[data-modal-trigger="${this.id}"]`)) {
      document
        .querySelectorAll(`[data-modal-trigger="${this.id}"]`)
        .forEach(($trigger: HTMLElement) => {
          $trigger.addEventListener('click', () => this.open());
        });
    }
    if (this.$modal.querySelector('[data-modal-close]')) {
      this.$modal.querySelectorAll('[data-modal-close]').forEach(($close: HTMLElement) => {
        $close.addEventListener('click', () => this.close());
      });
    }
    document.addEventListener('keydown', (e: any) => {
      if (Number(e.keyCode) === 27) this.close();
    });
  }
  public check() {}
}
