import swiper from 'swiper';

export class Jury {
  constructor() {
    const slider = new swiper('[data-jury-slider]', {
      speed: 800,
      simulateTouch: false,
      pagination: {
        el: '.jury__state',
        type: 'fraction',
      },
      navigation: {
        prevEl: '.jury__nav--prev',
        nextEl: '.jury__nav--next',
      },
      loop: true,

      slidesPerView: 3,
      slidesPerGroup: 3,
      breakpoints: {
        // 767: {
        //   slidesPerView: 2,
        //   slidesPerGroup: 2,
        // },
        1023: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
      },
      // other parameters
      on: {
        resize: () => {
        },
      },
    });
  }
}
