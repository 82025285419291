import { offset } from '../helpers/offset';
import { slideDown, slideUp } from 'slide-anim';

export class Fit {
  constructor($item, $parent) {
    const $trigger = $item.querySelector('[data-fit-trigger]');
    const $content = $item.querySelector('[data-fit-content]');
    const parentTop = offset($parent).top;
    const itemTop = offset($item).top;
    const elHeight = $item.clientHeight;
    const contentHeight = $content.clientHeight;
    const fromTop = `${itemTop - parentTop + elHeight}`;
    $item.classList.add('is-ready');

    ($content as HTMLElement).style.top = `${fromTop}px`;

    $trigger.addEventListener('click', () => {
      const $activeItem: HTMLElement = $parent.querySelector('[data-fit-item].is-active');
      const $activeContent = $activeItem ? $activeItem.querySelector('[data-fit-content]') : null;
      if (Boolean($activeItem)) {
        $activeItem.classList.remove('is-active');
        $activeItem.style.paddingBottom = '';
        ($activeContent as HTMLElement).style.height = '';
      }
      if ($item !== $activeItem) {
        $item.classList.add('is-active');
        $item.style.paddingBottom = `${contentHeight}px`;
        $content.style.height = `${contentHeight}px`;
      }
    });
  }
}
